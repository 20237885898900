<template>
    <div v-if="input" class="inline-block">
        <div :class="input ? 'w-full' : 'w-0'" class="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div :class="{ 'pb-20' : bottomOffset }" class="flex items-center justify-center min-h-screen p-4 text-center sm:block sm:p-0 relative">
                <!--
                Background overlay, show/hide based on modal state.

                Entering: "ease-out duration-300"
                    From: "opacity-0"
                    To: "opacity-100"
                Leaving: "ease-in duration-200"
                    From: "opacity-100"
                    To: "opacity-0"
                -->
                <transition
                    enter-active-class="transition-opacity ease-linear duration-300"
                    enter-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition-opacity ease-linear duration-300"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <div v-if="input" class="min-h-full absolute inset-0 bg-black bg-opacity-95 transition-opacity" aria-hidden="true" @click="toggleModal" />
                </transition>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <transition
                    enter-active-class="ease-out duration-300"
                    enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-active-class="ease-in duration-200"
                    leave-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div v-if="input" :class="`max-w-${maxWidth} w-full my-0 sm:my-12 inline-block align-bottom overflow-hidden rounded-lg text-left shadow-xl transform transition-all sm:align-middle sm:w-full divide-y divide-gray-200`">
                        <v-card class="divide-y-2 divide-default">
                            <v-section-heading>
                                <slot name="modal-header" :toggle-modal="toggleModal" />
                            </v-section-heading>

                            <div>
                                <slot :toggle-modal="toggleModal" />
                            </div>

                            <v-card-footer class="flex justify-between">
                                <slot name="modal-footer" :toggle-modal="toggleModal" />
                            </v-card-footer>
                        </v-card>
                    </div>
                </transition>
            </div>
        </div>

        <slot name="trigger" :toggle-modal="toggleModal" />
    </div>
</template>

<script>
export default {
    name: "Modal",
    props: {
        value: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        maxWidth: {
            type: String,
            required: false,
            default: () => "7xl",
        },
        bottomOffset: {
            type: Boolean,
            required: false,
            default: () => false,
        },
    },
    data() {
        return {
            input: false,
        };
    },
    watch: {
        value: {
            handler(value) {
                this.input = value;
            },
            immediate: true,
        },
        input: {
            handler(value) {
                this.$emit("input", value);
            },
        },
    },
    created() {
        document.addEventListener("keydown", (e) => {
            if (e.key === "Escape") {
                this.toggleModal();
            }
        });
    },
    methods: {
        toggleModal() {
            this.input = !this.input;
        },
    },
};
</script>
